import React from "react"
import SVGSprite from "./../Misc/SVGSprite"

import "../../styles/_global.styl"
import ButtonTickets from "../Buttons/ButtonTickets"
import ButtonBack from "../Buttons/ButtonBack"
import Header from "./Header"
import CookieConsent from "react-cookie-consent"
import CookieConsentment from "../Misc/CookieConsentment"

class Layout extends React.Component {
    constructor(props) {
        super(props)

        if (typeof window !== "undefined") {
            this.setCorrectVH()
            this.bindEvents()
        }
    }

    setCorrectVH() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    bindEvents() {
        window.addEventListener("resize", () => this.setCorrectVH())
        window.addEventListener("orientationchange", () => this.setCorrectVH())
    }

    render() {
        return (
            <div id="wrapper">
                <SVGSprite />
                <CookieConsentment />
                <ButtonTickets fixed={true} />
                <ButtonBack />
                <Header />
                <main className="main">{this.props.children}</main>
            </div>
        )
    }
}

export default Layout
