import React, { useState, createContext } from "react"

const initialState = {
    isHome: false,
}

export const CustomContext = createContext(initialState)

const CustomProvider = ({ children }) => {
    const [isHome, setIsHome] = useState(false)
    const [isCookieConsented, setIsCookieConsented] = useState(false)
    const [topScrollTriger, setTopScrollTriger] = useState(0)
    const [bottomScrollTrigger, setBottomScrollTrigger] = useState(0)
    const [posBtnTickets, setPosBtnTickets] = useState(0)
    const [headerHeight, setHeaderHeight] = useState(0)
    const [transitionStatus, setTransitionStatus] = useState({
        status: null,
        mount: null,
    })

    return (
        <CustomContext.Provider
            value={{
                isHome,
                setIsHome,
                topScrollTriger,
                setTopScrollTriger,
                posBtnTickets,
                setPosBtnTickets,
                transitionStatus,
                setTransitionStatus,
                headerHeight,
                setHeaderHeight,
                bottomScrollTrigger,
                setBottomScrollTrigger,
                isCookieConsented,
                setIsCookieConsented,
            }}
        >
            {children}
        </CustomContext.Provider>
    )
}

export default CustomProvider
