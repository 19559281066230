import React, { useEffect, useState, useContext } from "react"
import ButtonLink from "../../Buttons/ButtonLink"

import { useStaticQuery, graphql } from "gatsby"
import { CustomContext } from "../../../context/CustomProvider"

import ButtonTicketsStyles from "./ButtonTickets.module.styl"

const ButtonTickets = ({ fixed }) => {
    const queryResult = useStaticQuery(query)
    const data = queryResult.buttonTicketsJson.buttonTickets

    const { topScrollTriger, bottomScrollTrigger, isHome } = useContext(
        CustomContext
    )
    const [isScrolled, setIsScrolled] = useState(false)
    const [isScrolledBottom, setIsScrolledBottom] = useState(false)
    const [windowHeight, setWindowHeight] = useState(0)
    const [isCookieConsent, setIsCookieConsent] = useState(false)
    const [bottomPos, setBottomPos] = useState(0)

    const onWindowScroll = e => {
        let doc = document.documentElement,
            scrollTop =
                (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

        if (
            bottomScrollTrigger > 0 &&
            scrollTop + windowHeight >= bottomScrollTrigger
        ) {
            setIsScrolledBottom(true)
        } else if (topScrollTriger > 0 && scrollTop > topScrollTriger) {
            setIsScrolledBottom(false)
            if (!isScrolled) {
                setIsScrolled(true)
            }
            return
        } else {
            setIsScrolledBottom(false)
            setIsScrolled(false)
        }
    }

    const onResize = () => {
        setWindowHeight(window.innerHeight)
        checkCookieBtn()
    }

    const checkCookieBtn = () => {
        const cookieConsent = document.querySelector('.cookieConsent')
        if (cookieConsent) {
            setIsCookieConsent(true)
            setBottomPos(cookieConsent.clientHeight)
        } else {
            setIsCookieConsent(false)
            setBottomPos(0)
        }
    }

    useEffect(() => {
        onResize()
        window.addEventListener("resize", onResize)
        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onWindowScroll)
        return () => {
            window.removeEventListener("scroll", onWindowScroll)
        }
    }, [topScrollTriger, isScrolled])

    useEffect(() => {
        checkCookieBtn()
    })

    return (
        <>
            {fixed && (
                <>
                    <div
                        className={`${
                            ButtonTicketsStyles.ButtonTicketsFixedTop
                        } ${
                            isScrolled || !isHome
                                ? ButtonTicketsStyles.ButtonTicketScrolled
                                : ""
                        }`}
                    >
                        <ButtonLink
                            title={data.cta.title}
                            to={data.cta.url}
                            external={true}
                        />
                    </div>
                    <div
                        style={isCookieConsent ? {bottom: `${bottomPos}px`} : {}}
                        className={`${
                            ButtonTicketsStyles.ButtonTicketsFixedBottom
                        } ${isCookieConsent ? ButtonTicketsStyles.ButtonTicketsFixedBottomCookie : '' }
                        ${
                            isScrolled && isHome && !isScrolledBottom
                                ? ButtonTicketsStyles.ButtonTicketScrolled
                                : ""
                        }`}
                    >
                        <ButtonLink
                            title={data.cta.title}
                            external={true}
                            to={data.cta.url}
                            extraClasses={`${ButtonTicketsStyles.ButtonLinkFull} js-btn-ticket`}
                        />
                    </div>
                </>
            )}

            {!fixed && (
                <div className={`${ButtonTicketsStyles.ButtonTicketsTop}`}>
                    <ButtonLink
                        title={data.cta.title}
                        to={data.cta.url}
                        extraClasses={`${ButtonTicketsStyles.ButtonLink}`}
                        external={true}
                    />
                </div>
            )}
        </>
    )
}

const query = graphql`
    query ButtonTicketsyQuery {
        buttonTicketsJson {
            buttonTickets {
                cta {
                    title
                    url
                }
            }
        }
    }
`

export default ButtonTickets
