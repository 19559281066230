import React, { useRef, useEffect, createRef, useState } from "react"
import LinkPrimary from "../../Links/LinkPrimary"
import Redirect from "../../../modules/Redirect"

import ListNavStyles from "./ListNav.module.styl"

const ListNav = props => {
    const linkRef = useRef([...Array(2)].map(() => createRef()))
    const linkRefTarget = linkRef.current
        ? linkRef.current.map(item => {
              if (item.current && item.current.getAttribute("href")) {
                  return item.current
                      .getAttribute("href")
                      .replace(/[&/#,+()$~%.'":*?<>{}]/g, "")
              }
              return null
          })
        : []
    const targetElements =
        linkRefTarget.length > 0
            ? linkRefTarget.map(item => {
                  if (item) {
                      const targetElement = document.getElementById(item)
                      if (targetElement) {
                          return {
                              top: targetElement.offsetTop,
                              bottom:
                                  targetElement.clientHeight +
                                  targetElement.offsetTop,
                          }
                      }
                  }
                  return null
              })
            : []
    const [isActive, setIsActive] = useState(-1)
    const [windowHeight, setWindowHeight] = useState(0)

    const minScrollRatio = 0
    const maxScrollRatio = 0.5

    const onClickLink = e => {
        const redirect = new Redirect({
            headerHeight: props.headerHeight,
        })
        redirect.goToFromLink(e)
        props.onClickLink()
    }

    const onScroll = () => {
        let activeIndex = -1

        if (window.scrollY > 0) {
            targetElements.forEach((target, index) => {
                if (target && target.bottom) {
                    const offsetBottomY = target.bottom - window.scrollY
                    const offsetTopY = target.top - window.scrollY
                    const offsetTopYRatio = offsetTopY / windowHeight
                    const offsetBottomYRatio = offsetBottomY / windowHeight

                    if (
                        (offsetBottomYRatio > minScrollRatio &&
                            offsetBottomYRatio < maxScrollRatio) ||
                        (offsetTopYRatio > minScrollRatio &&
                            offsetTopYRatio < maxScrollRatio) ||
                        (window.scrollY > target.top &&
                            window.scrollY < target.bottom)
                    ) {
                        activeIndex = index
                    }
                }
            })
        }

        setIsActive(activeIndex)
    }

    const onResize = () => {
        setWindowHeight(window.innerHeight)
    }

    useEffect(() => {
        onResize()
        window.addEventListener("resize", onResize)
        return window.removeEventListener("resize", onResize)
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [linkRef, linkRefTarget, targetElements])

    return (
        <ul className={ListNavStyles.ListNav}>
            <li className={ListNavStyles.ListItem}>
                <LinkPrimary
                    external={props.isHome ? true : false}
                    forwardedRef={linkRef.current[0]}
                    extraClasses={`${ListNavStyles.ListLink} ${
                        isActive === 0 ? ListNavStyles.ListLinkActive : ""
                    }`}
                    extraClassesTitle={ListNavStyles.ListLinkTitle}
                    url={props.isHome ? "/#program-and-speakers" : "/"}
                    hash={"#program-and-speakers"}
                    title={"Program and speakers"}
                    content={`Programme &amp; speakers`}
                    onClickClb={props.isHome ? onClickLink : props.onClickLink}
                />
            </li>
            <li className={ListNavStyles.ListItem}>
                <LinkPrimary
                    external={props.isHome ? true : false}
                    forwardedRef={linkRef.current[1]}
                    extraClasses={`${ListNavStyles.ListLink} ${
                        isActive === 1 ? ListNavStyles.ListLinkActive : ""
                    }`}
                    extraClassesTitle={ListNavStyles.ListLinkTitle}
                    url={props.isHome ? "/#infos-and-contact" : "/"}
                    title={"Infos and contact"}
                    hash={"#infos-and-contact"}
                    content={`Infos &amp; contact`}
                    onClickClb={props.isHome ? onClickLink : props.onClickLink}
                />
            </li>
        </ul>
    )
}

export default ListNav
