import React, { useContext, useState, useEffect } from "react"
import { CustomContext } from "../../../context/CustomProvider"
import CookieConsent from "react-cookie-consent"

const CookieConsentment = () => {
    const { setIsCookieConsented } = useContext(CustomContext)
    const [gutter, setGutter] = useState(0)

    const onResize = () => {
        setGutter(getGridSpacer())
    }

    const getGridSpacer = () => {
        return typeof window !== "undefined"
            ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--grid-spacer"
              )
            : ""
    }

    useEffect(() => {
        onResize()
        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, [])

    return (
        <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="OK"
            cookieName="Grow2k19"
            onAccept={() => setIsCookieConsented(true)}
            buttonStyle={{
                fontSize: "1.4rem",
                background: "#fff",
                border: ".2rem solid #fff",
                borderRadius: "2rem",
                margin: "0 0 2rem .8rem",
                padding: "1rem 2rem",
                fontFamily: "Favorit, sans-serif",
            }}
            declineButtonStyle={{
                fontSize: "1.4rem",
                background: "#000",
                border: ".2rem solid #fff",
                borderRadius: "2rem",
                margin: "0 .8rem 2rem",
                padding: "1rem 2rem",
                fontFamily: "Favorit, sans-serif",
            }}
            declineButtonText={"Refuser"}
            contentStyle={{ margin: "0 0 2rem" }}
            style={{
                background: "#000",
                borderTop: ".4rem solid #fff",
                fontSize: "1.8rem",
                padding: `2rem ${gutter} 0`,
                boxSizing: "border-box",
            }}
        >
            En poursuivant votre navigation sur ce site, vous acceptez
            l’utilisation de cookies. Cela afin de réaliser des statistiques de
            visites.
        </CookieConsent>
    )
}

export default CookieConsentment
