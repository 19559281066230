import React from "react"
import PropTypes from "prop-types"

import ButtonLinkStyles from "./ButtonLink.module.styl"
import AnimatedLink from "../../Misc/AnimatedLink/AnimatedLink"

const ButtonLink = props => {
    const className =
        props.type === "white"
            ? ButtonLinkStyles.ButtonLinkWhite
            : ButtonLinkStyles.ButtonLinkBlack

    if (props.external) {
        return (
            <a
                href={props.to}
                target="_blank"
                rel="noopener noreferrer"
                title={props.title}
                className={`${className} ${
                    props.extraClasses ? props.extraClasses : ""
                }`}
            >
                {" "}
                <span className={ButtonLinkStyles.ButtonLinkTitle}>
                    {props.title}
                </span>
            </a>
        )
    } else {
        return (
            <AnimatedLink
                to={props.to}
                className={`${className} ${
                    props.extraClasses ? props.extraClasses : ""
                }`}
                title={props.title}
                exit={{
                    duration: 2,
                    scrollDelayAllow: 2,
                    delay: 0,
                    zIndex: 2,
                }}
                entry={{
                    delay: 0,
                    duration: 2,
                    zIndex: 1,
                }}
            >
                <span className={ButtonLinkStyles.ButtonLinkTitle}>
                    {props.title}
                </span>
            </AnimatedLink>
        )
    }
}

ButtonLink.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    extraClasses: PropTypes.string,
    to: PropTypes.string,
    external: PropTypes.bool,
}

export default ButtonLink
