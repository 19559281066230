import ScrollTo from "../helpers/ScrollTo"
import getPosition from "../helpers/positions"
import EASINGS from "../helpers/easings"

export default class Redirect {
    constructor(props) {
        this.headerHeight = props.headerHeight
    }

    getLinkUrl(e) {
        const currentTarget = e.currentTarget
        const targetHref = currentTarget.getAttribute("href")

        return targetHref
    }

    goToFromLink(e) {
        this.goTo(this.getLinkUrl(e))
    }

    goToFromLoad() {
        if (window.location.hash) {
            this.goTo(window.location.hash)
        }
    }

    goTo(targetHref) {
        const target = targetHref.replace(/[&/#,+()$~%.'":*?<>{}]/g, "")
        const targetZone = document.getElementById(target)

        if (targetZone) {
            new ScrollTo(document.body, {
                dist: getPosition(targetZone).top - this.headerHeight,
                easing: EASINGS.easeOutQuart,
                duration: 1000,
            })
            window.history.pushState(null, null, `#${target}`)
        } else {
            window.location = `${window.origin}${targetHref}`
        }
    }
}
