import React from "react"
import PropTypes from "prop-types"
import AnimatedLink from "../../Misc/AnimatedLink/AnimatedLink"

import LinkPrimaryStyle from "./LinkPrimary.module.styl"

const LinkPrimary = props => {
    const onClick = e => {
        if (props.onClickClb) {
            if (e) {
                e.preventDefault()
            }
            props.onClickClb(e)
        }
    }
    const renderLink = () => {
        if (props.external) {
            return (
                <a
                    className={`${LinkPrimaryStyle.LinkPrimary} ${
                        props.extraClasses ? props.extraClasses : ""
                    }`}
                    href={props.url}
                    target={props.blank ? "_blank" : ""}
                    rel={props.blank ? "noopener noreferrer" : ""}
                    ref={props.forwardedRef ? props.forwardedRef : null}
                    title={props.title ? props.title : props.content}
                    onClick={onClick}
                >
                    <span
                        className={`${LinkPrimaryStyle.LinkTitle} ${props.extraClassesTitle}`}
                        dangerouslySetInnerHTML={{ __html: props.content }}
                    ></span>
                </a>
            )
        }
        return (
            <span ref={props.forwardedRef ? props.forwardedRef : null}>
                <AnimatedLink
                    to={props.url}
                    hash={props.hash}
                    onClick={onClick}
                    className={`${LinkPrimaryStyle.LinkPrimary} ${
                        props.extraClasses ? props.extraClasses : ""
                    }`}
                    title={props.title ? props.title : props.content}
                    exit={{
                        duration: 1,
                        scrollDelayAllow: 1,
                        delay: 0,
                        zIndex: 1,
                    }}
                    entry={{
                        delay: 0,
                        duration: 1,
                        zIndex: 2,
                    }}
                >
                    <span
                        className={LinkPrimaryStyle.LinkTitle}
                        dangerouslySetInnerHTML={{ __html: props.content }}
                    ></span>
                </AnimatedLink>
            </span>
        )
    }

    return <>{renderLink()}</>
}

LinkPrimary.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    extraClasses: PropTypes.string,
    extraClassesTitle: PropTypes.string,
    external: PropTypes.bool,
    blank: PropTypes.bool,
}

export default LinkPrimary
