import React from "react"
import PropTypes from "prop-types"

const Panel = props => {
    return (
        <div
            className={`${props.extraClasses} leader-72 leader-40-tp`}
            id={props.id ? props.id : ""}
        >
            {props.children}
        </div>
    )
}

Panel.propTypes = {
    extraClasses: PropTypes.string,
}

export default Panel
