/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import CustomProvider from "./src/context/CustomProvider"

export const wrapRootElement = ({ element }) => (
  <CustomProvider>{element}</CustomProvider>
)

export const shouldUpdateScroll = () => false

// SW
export const onServiceWorkerUpdateReady = () => {
    window.location.reload();
}
