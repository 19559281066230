/**
 * ScrollTo
 */
import easings from "./easings"
import getPosition from "./positions"

export default class ScrollTo {
    constructor(element, options) {
        options = options || {}
        this.el = element
        this.dist = options.dist || 0
        this.duration = options.duration || 300
        this.easing = options.easing || easings.easeOutQuad
        this.callback = options.callback || null

        this.init()
    }

    init() {
        this.startTime()
        this.calcDestinationOffsetToScroll()
        this.fallBackRequestAnimationFrame()
        this.scroll()
    }

    startTime() {
        // Store initial position of a window and time
        // If performance is not available in your browser
        // It will fallback to new Date().getTime() - thanks IE < 10
        this.start = window.pageYOffset
        this.startTime =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime()
    }

    calcDestinationOffsetToScroll() {
        // Take height of window and document to sesolve max scrollable value
        // Prevent requestAnimationFrame() from scrolling below maximum scollable value
        // Resolve destination type (node or number)
        this.documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        )
        this.windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName("body")[0].clientHeight
        this.destinationOffset =
            typeof this.el === "number"
                ? this.el + this.dist
                : getPosition(this.el).top + this.dist
        this.destinationOffsetToScroll =
            this.documentHeight - this.destinationOffset < this.windowHeight
                ? Math.round(this.documentHeight - this.windowHeight)
                : Math.round(this.destinationOffset)
    }

    fallBackRequestAnimationFrame() {
        // If requestAnimationFrame is not supported
        // Move window to destination position and trigger callback function
        if ("requestAnimationFrame" in window === false) {
            window.scroll(0, this.destinationOffsetToScroll)
            if (this.callback) {
                this.callback()
            }
        }
    }

    scroll() {
        // function resolves position of a window and moves to exact amount of pixels
        // Resolved by calculating delta and timing function choosen by user
        const now =
            "now" in window.performance
                ? performance.now()
                : new Date().getTime()
        const time = Math.min(1, (now - this.startTime) / this.duration)
        const timeFunction = this.easing(time)
        window.scroll(
            0,
            Math.ceil(
                timeFunction * (this.destinationOffsetToScroll - this.start) +
                    this.start
            )
        )

        // Stop requesting animation when window reached its destination
        // And run a callback function
        if (time >= 1) {
            if (this.callback) {
                this.callback()
            }
            return
        }

        // If window still needs to scroll to reach destination
        // Request another scroll invokation
        requestAnimationFrame(this.scroll.bind(this))
    }
}
