import React from "react"

import SVGSpriteStyles from "./SVGSprite.module.styl"

const SVGSprite = () => {
    return (
        <svg className={SVGSpriteStyles.SVGSprite}>
            <defs>
                <symbol id="icon-logo" viewBox="0 0 32 32">
                    <circle
                        cx="16"
                        cy="16"
                        r="12"
                        strokeWidth="8"
                        fillRule="evenodd"
                    />
                </symbol>

                <symbol id="icon-burger" viewBox="0 0 24 18">
                    <g fillRule="evenodd">
                        <path d="M0 0h24v4H0zM0 7h24v4H0zM0 14h24v4H0z" />
                    </g>
                </symbol>

                <symbol id="icon-arrow-left" viewBox="0 0 19 16">
                    <path
                        d="M7.996 16l1.928-1.987L5.348 9.47H19V6.529H5.322l4.602-4.542L7.996 0 0 7.974z"
                        fillRule="nonzero"
                    />
                </symbol>

                <symbol id="icon-arrow-up-left" viewBox="0 0 20 20">
                    <path
                        fill="#000"
                        fillRule="nonzero"
                        d="M19.162 14.61l-3.44-.044.046-8.01L3.753 18.57l-2.58-2.58L13.211 3.953l-8.033.068-.045-3.44 14.03-.044z"
                    />
                </symbol>

                <symbol id="icon-close" viewBox="0 0 16 16">
                    <path
                        d="M7.778 4.95L12.728 0l2.828 2.828-4.95 4.95 4.95 4.95-2.828 2.828-4.95-4.95-4.95 4.95L0 12.728l4.95-4.95L0 2.828 2.828 0l4.95 4.95z"
                        fillRule="evenodd"
                    />
                </symbol>

                <symbol id="icon-play" viewBox="0 0 20 26">
                    <path
                        d="M1.854 3.474v20.052L16.881 13.5 1.854 3.474z"
                        strokeWidth="3.2"
                        fillRule="evenodd"
                    />
                </symbol>

                <symbol id="icon-pause" viewBox="0 0 10 12">
                    <g fillRule="evenodd">
                        <path d="M0 0h4v12H0zM6 0h4v12H6z" />
                    </g>
                </symbol>
            </defs>
        </svg>
    )
}
export default SVGSprite
