import React, { useEffect, useState, useRef, useContext } from "react"
import { Row, Cell } from "../Grid"

import { CustomContext } from "../../../context/CustomProvider"

import ListSocial from "../../Lists/ListSocial"
import ListNav from "../../Lists/ListNav"
import Icon from "../../Misc/Icon"
import AnimatedLink from "../../Misc/AnimatedLink/AnimatedLink"

import Redirect from "../../../modules/Redirect"

import HeaderStyles from "./Header.module.styl"

const Header = () => {
    const { isHome, transitionStatus, setHeaderHeight } = useContext(
        CustomContext
    )

    const headerRef = useRef(null)
    const headerExpandRef = useRef(null)
    const [isFixed, setIsFixed] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [hasScrolledFirst, setHasScrolledFirst] = useState(false)
    const headerHeight = headerRef.current ? headerRef.current.clientHeight : 0
    const topTrigger = headerHeight

    const redirect = new Redirect({
        headerHeight: headerHeight,
    })

    const onWindowScroll = e => {
        let doc = document.documentElement,
            scrollTop =
                (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

        if (scrollTop > topTrigger) {
            if (!isFixed) {
                setIsFixed(true)
            }
            return
        } else {
            setIsFixed(false)
        }
    }

    const onClickClose = () => {
        setIsOpen(false)
    }

    const onClickLink = () => {
        setIsOpen(false)
    }

    const onClickLogo = e => {
        setIsOpen(false)

        e.preventDefault()
        redirect.goToFromLink(e)
    }

    const onClickBurger = e => {
        if (isOpen) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

    const renderBackHomeLink = isMobile => {
        if (isHome) {
            return (
                <a
                    href="/#grow"
                    title="GROW 2.5"
                    className={
                        isMobile
                            ? HeaderStyles.HeaderLogoMobile
                            : HeaderStyles.HeaderLogo
                    }
                    onClick={onClickLogo}
                >
                    <Icon
                        name="logo"
                        width={isMobile ? 24 : 32}
                        height={isMobile ? 24 : 32}
                        fill="#fff"
                        stroke="#000"
                    />
                </a>
            )
        } else {
            return (
                <AnimatedLink
                    to="/"
                    hash="#grow"
                    title="GROW 2.5"
                    className={`${
                        isMobile
                            ? HeaderStyles.HeaderLogoMobile
                            : HeaderStyles.HeaderLogo
                    }`}
                    exit={{
                        duration: 2,
                        scrollDelayAllow: 1,
                        delay: 0,
                        zIndex: 2,
                    }}
                    entry={{
                        delay: 0,
                        duration: 2,
                        zIndex: 1,
                    }}
                >
                    <Icon
                        name="logo"
                        width={isMobile ? 24 : 32}
                        height={isMobile ? 24 : 32}
                        fill="#fff"
                        stroke="#000"
                    />
                </AnimatedLink>
            )
        }
    }

    useEffect(() => {
        if (transitionStatus && transitionStatus.status === "exiting") {
            setHasScrolledFirst(false)
        }
    }, [transitionStatus])

    useEffect(() => {
        setHeaderHeight(headerHeight)
        if (
            headerHeight &&
            !hasScrolledFirst &&
            ((transitionStatus.status === "exited" &&
                !transitionStatus.mount) ||
                (transitionStatus.status === "entered" &&
                    transitionStatus.mount))
        ) {
            redirect.goToFromLoad()
            setHasScrolledFirst(true)
        }
    }, [headerRef.current, transitionStatus])

    useEffect(() => {
        window.addEventListener("scroll", onWindowScroll)
        return () => {
            window.removeEventListener("scroll", onWindowScroll)
        }
    }, [topTrigger, isFixed, headerRef.current])

    return (
        <header
            className={`${
                isFixed ? HeaderStyles.HeaderFixed : HeaderStyles.Header
            }
            ${isOpen ? HeaderStyles.HeaderOpened : ""} js-header
            ${
                transitionStatus &&
                (transitionStatus.status === "exiting" ||
                    transitionStatus.status === "entering")
                    ? HeaderStyles.HeaderBlocked
                    : ""
            }
            `}
            ref={headerRef}
        >
            <Row>
                <Cell start="0" end="12">
                    <div className={HeaderStyles.HeaderInner}>
                        <div className={HeaderStyles.HeaderBar}>
                            {renderBackHomeLink(true)}
                            <button
                                title={`${
                                    isOpen ? "Fermer" : "Ouvrir"
                                } le menu`}
                                className={HeaderStyles.HeaderBurger}
                                onClick={onClickBurger}
                            >
                                <Icon
                                    name="burger"
                                    fill="#000"
                                    width={24}
                                    height={18}
                                    extraClasses={`${HeaderStyles.HeaderBurgerIcon} ${HeaderStyles.HeaderBurgerIconOpen}`}
                                />
                                <Icon
                                    name="close"
                                    width={20}
                                    height={20}
                                    fill="#000"
                                    extraClasses={`${HeaderStyles.HeaderBurgerIcon} ${HeaderStyles.HeaderBurgerIconClose}`}
                                />
                            </button>
                        </div>
                        <div
                            className={HeaderStyles.HeaderExpand}
                            ref={headerExpandRef}
                        >
                            <div className={HeaderStyles.HeaderLeft}>
                                {renderBackHomeLink()}
                                <nav className={HeaderStyles.HeaderNav}>
                                    <ListNav
                                        headerHeight={headerHeight}
                                        onClickLink={onClickLink}
                                        isHome={isHome}
                                    />
                                </nav>
                            </div>
                            <div className={HeaderStyles.HeaderRight}>
                                <ListSocial />
                            </div>
                        </div>
                        <button
                            title="Fermer le menu"
                            className={HeaderStyles.HeaderClose}
                            onClick={onClickClose}
                        ></button>
                    </div>
                </Cell>
            </Row>
        </header>
    )
}

export default Header
