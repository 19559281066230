import React, { useContext } from "react"

import AnimatedLink from "../../Misc/AnimatedLink/AnimatedLink"
import Icon from "../../Misc/Icon"

import ButtonBackStyles from "./ButtonBack.module.styl"
import { CustomContext } from "../../../context/CustomProvider"

const ButtonBack = () => {
    const { isHome, transitionStatus } = useContext(CustomContext)
    const isTransitioning =
        transitionStatus &&
        transitionStatus.status &&
        (transitionStatus.status === "exiting" ||
            transitionStatus.status === "POP" ||
            transitionStatus.status === "entering")
    const isTransitioningIn =
        transitionStatus &&
        transitionStatus.status &&
        transitionStatus.status === "entering"
    const visibleClass =
        (isTransitioning && !isHome) || !isHome
            ? ButtonBackStyles.ButtonBackVisible
            : ""
    const visibleInClass =
        (isTransitioningIn && !isHome) || !isHome
            ? ButtonBackStyles.ButtonBackVisibleIn
            : ""

    return (
        <AnimatedLink
            to="/"
            title="Retour à l'accueil "
            className={`${
                ButtonBackStyles.ButtonBack
            } btn--nav ${visibleClass} ${visibleInClass} ${
                isHome ? ButtonBackStyles.ButtonBackHidden : ""
            }`}
            exit={{
                duration: 2,
                scrollDelayAllow: 2,
                delay: 0,
                zIndex: 2,
            }}
            entry={{
                delay: 0,
                duration: 2,
                zIndex: 1,
            }}
        >
            <Icon
                name="arrow-left"
                width={19}
                height={16}
                fill="#000"
                extraClasses={ButtonBackStyles.ButtonBackIcon}
            />
        </AnimatedLink>
    )
}

export default ButtonBack
