import React from "react"

import ListSocialStyles from "./ListSocial.module.styl"
import LinkPrimary from "../../Links/LinkPrimary"

const ListSocial = () => {
    return (
        <ul className={ListSocialStyles.ListSocial}>
            <li className={ListSocialStyles.ListItem}>
                <LinkPrimary
                    external={true}
                    blank={true}
                    url="https://www.facebook.com/GROW.Paris/"
                    title="Page Facebook de GROW"
                    content="Fb"
                />
            </li>
            <li className={ListSocialStyles.ListItem}>
                <LinkPrimary
                    external={true}
                    blank={true}
                    url="https://twitter.com/GROW_Paris"
                    title="Page Twitter de GROW"
                    content="Tw"
                />
            </li>
            <li className={ListSocialStyles.ListItem}>
                <LinkPrimary
                    external={true}
                    blank={true}
                    url="https://www.instagram.com/grow.paris/"
                    title="Page Instagram de GROW"
                    content="Ig"
                />
            </li>
            <li className={ListSocialStyles.ListItem}>
                <LinkPrimary
                    external={true}
                    blank={true}
                    url="https://medium.com/grow-paris"
                    title="Page Medium de GROW"
                    content="Md"
                />
            </li>
        </ul>
    )
}

export default ListSocial
