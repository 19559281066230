import React from "react"
import PropTypes from "prop-types"

const Cell = props => {
    const tlCell =
        props.tl && props.tl.start && props.tl.end
            ? `cell-start-${props.tl.start}-tl cell-end-${props.tl.end}-tl`
            : ``
    const tpCell =
        props.tp && props.tp.start && props.tp.end
            ? `cell-start-${props.tp.start}-tp cell-end-${props.tp.end}-tp`
            : ``

    return (
        <div
            className={`cell-start-${props.start} cell-end-${props.end} ${
                props.gridRowTp ? `grid-row-${props.gridRowTp}-tp` : ""
            } ${tpCell} ${tlCell}
            `}
        >
            {props.children}
        </div>
    )
}

Cell.propTypes = {
    start: PropTypes.string,
    end: PropTypes.string,
    gridRowTp: PropTypes.string,
    tp: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
    }),
    tl: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
    }),
}

export default Cell
