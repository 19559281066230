import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"

import TransitionLink from "gatsby-plugin-transition-link"

import { CustomContext } from "../../../context/CustomProvider"
import { useTransitionState } from "gatsby-plugin-transition-link/hooks"

const AnimatedLink = props => {
    const { setTransitionStatus, headerHeight } = useContext(CustomContext)
    const transitionState = useTransitionState()
    const wrapper =
        typeof document !== "undefined"
            ? document.getElementById("wrapper")
            : null
    const timeUnit = 1000

    const onClick = e => {
        e.preventDefault()

        if (props.onClick) {
            props.onClick()
        }
    }

    useEffect(() => {
        if (transitionState && transitionState.transitionStatus) {
            setTransitionStatus({
                status: transitionState.transitionStatus,
                mount: transitionState.mount,
            })
        }
    }, [transitionState])

    return (
        <TransitionLink
            to={props.to}
            title={props.title}
            onClick={onClick}
            className={props.className}
            exit={{
                length: props.exit.duration,
                delay: props.exit.delay,
                zIndex: props.exit.zIndex,
                trigger: ({ node }) => {
                    const scrollTop =
                        (document.scrollingElement &&
                            document.scrollingElement.scrollTop) ||
                        document.body.scrollTop ||
                        window.pageYOffset

                    document.body.scrollTo(0, 0)

                    const transitionElement = node.querySelector(".container")

                    if (props.hash) {
                        window.location.hash = props.hash
                    }

                    const timeOut = setTimeout(
                        () => {
                            wrapper.style.overflowY = "auto"
                            wrapper.style.height = "auto"

                            wrapper.parentElement.style.overflowY = "auto"
                            wrapper.parentElement.style.height = "auto"

                            document.body.scrollTo(0, 0)

                            clearTimeout(timeOut)
                        },
                        props.exit.scrollDelayAllow
                            ? props.exit.scrollDelayAllow * timeUnit
                            : (props.exit.duration + props.exit.delay) *
                                  timeUnit
                    )

                    const height = window.innerHeight - headerHeight

                    transitionElement.style.overflowY = "hidden"
                    transitionElement.style.height = `${height}px`
                    transitionElement.scrollTop = scrollTop

                    wrapper.style.overflowY = "hidden"
                    wrapper.style.height = "100vh"
                },
            }}
            entry={{
                length: props.entry.duration,
                delay: props.entry.delay,
                zIndex: props.entry.zIndex,
                trigger: (node, enter, exit) =>
                    props.entry.trigger
                        ? props.entry.trigger({ node, enter, exit })
                        : () => {},
            }}
        >
            {props.children}
        </TransitionLink>
    )
}

AnimatedLink.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    exit: PropTypes.shape({
        duration: PropTypes.number,
        delay: PropTypes.number,
        zIndex: PropTypes.number,
        trigger: PropTypes.func,
    }),
    entry: PropTypes.shape({
        duration: PropTypes.number,
        delay: PropTypes.number,
        zIndex: PropTypes.number,
        trigger: PropTypes.func,
    }),
}

AnimatedLink.defaultProps = {
    to: "/",
    title: "",
    onClick: () => {},
    className: "",
    exit: {
        duration: 2,
        scrollDelayAllow: 0,
        delay: 0,
        zIndex: 2,
        trigger: () => {},
    },
    entry: {
        duration: 2,
        delay: 0,
        zIndex: 1,
        trigger: () => {},
    },
}

export default AnimatedLink
