module.exports = [{
      plugin: require('/Users/cbardon/Sites/grow-2k19/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/Users/cbardon/Sites/grow-2k19/src/components/Layout/index.jsx"},
    },{
      plugin: require('/Users/cbardon/Sites/grow-2k19/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cbardon/Sites/grow-2k19/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
